import * as React from "react";
import * as styles from "./hero-video-section.module.css";

import {
  Button,
  Container,
  HStack,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent, useCallback, useState } from "react";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import PromoBanner from "./PromoBanner/Banner";
import { navigate } from "gatsby";

const HeroVideoSection: FunctionComponent = (props) => {
  const [error, setError] = useState(false);
  const handleVideoError = () => {
    setError(true);
  };

  return (
    <div className={styles.herosection}>
      {error && (
        <Image
          src={"/doctormedica.webp"}
          alt="Fallback image"
          position="absolute"
          loading="eager"
        />
      )}
      <video
        className={styles.herobackground}
        playsInline
        autoPlay
        muted
        loop
        onLoadStart={(e: any) => (e.target.volume = 0)}
        poster="/doctormedica.webp"
        onError={handleVideoError}>
        <source
          className={styles.herobackground}
          src="https://forgahosting.com/materials/drmdc/doctor-medica-hero.mp4"
        />
      </video>

      <div className={styles.overlay} />
      <Container maxW={" 100%"} px={{ base: 9, md: 32 }}>
        <Stack
          flex={1}
          flexFlow={{ base: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          w="100%">
          <VStack
            justifyContent="center"
            alignItems={{ base: "center", md: "flex-start" }}
            textAlign="left"
            maxW={{ base: "100%", md: "640px" }}
            className={styles.heroContent}
            gap={"8px"}>
            <Heading
              as="h1"
              fontSize={{ base: "32px !important", md: "48px !important" }}
              fontWeight={400}
              className={styles.headingLarge3xl}>
              Affordable Since 2006: Medical & Aesthetic Supplies for Less
            </Heading>
            <Text fontSize="16px">
              Extensive portfolio, low prices, personalized service. Find
              everything you need for your practice success.
            </Text>
            <Link href="/shop" m={0} _hover={{ textDecoration: "none" }}>
              <Button
                color="gray.900"
                bg="#fff"
                borderRadius={"48px"}
                fontFamily="Montserrat"
                _hover={{ bg: "#000", color: "#fff" }}>
                Unlock Savings
              </Button>
            </Link>
          </VStack>

          <PromoBanner />
        </Stack>
        <div className={styles.sectionicon}>
          <AnchorLink to="/#anchorlink">
            <img
              className={styles.remixIconslinesystemarrow}
              alt=""
              src="/remixiconslinesystemarrowdownsline.svg"
            />
          </AnchorLink>
        </div>
      </Container>
    </div>
  );
};

export default HeroVideoSection;
