import { Box, Link } from "@chakra-ui/react";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import useAuth from "../useAuth";

function PromoBanner() {
  const { loggedIn, isUnlicensedUser } = useAuth();

  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "home-page" }) {
        promoBanner {
          bannerLicensed {
            linkToLicensed
            bannerImageLicensed {
              sourceUrl
            }
          }
          bannerUnlicensed {
            linkTo
            bannerImage {
              sourceUrl
            }
          }
        }
      }
    }
  `);

  let bannerData;
  if (!loggedIn) {
    // Case 1: User is not logged in
    bannerData = data.wpPage.promoBanner.bannerUnlicensed;
  } else if (isUnlicensedUser) {
    // Case 2: User is logged in but unlicensed
    bannerData = data.wpPage.promoBanner.bannerUnlicensed;
  } else {
    // Case 3: User is logged in and licensed
    bannerData = data.wpPage.promoBanner.bannerLicensed;
  }

  return (
    // <>
    //   <Link zIndex={2} href="/promotions" _hover={{ color: "gray.900" }}>
    //     <Box
    //       // position='absolute'
    //       // top={{ base: '714px', sm: '614px' }}
    //       // left='50%'
    //       // transform='translate(-50%, -50%)'
    //       p={{ base: "40px 20px", sm: "40px" }}
    //       minW={{ base: "100%", sm: "320px" }}
    //       w="320px"
    //       h="400px"
    //       borderRadius="12px"
    //       boxShadow="0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);"
    //       bgImage="/banner.webp"
    //       bgPosition="center"
    //       bgSize="cover"
    //       bgRepeat="no-repeat"></Box>
    //   </Link>
    // </>
    <Link
      zIndex={2}
      href={bannerData.linkToLicensed || bannerData.linkTo}
      _hover={{ color: "gray.900" }}>
      <Box
        p={{ base: "40px 20px", sm: "40px" }}
        minW={{ base: "100%", sm: "320px" }}
        w="320px"
        h="400px"
        borderRadius="12px"
        boxShadow="0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10);"
        bgImage={`url(${
          bannerData.bannerImageLicensed?.sourceUrl ||
          bannerData.bannerImage?.sourceUrl
        })`}
        bgPosition="center"
        bgSize="cover"
        bgRepeat="no-repeat"></Box>
    </Link>
  );
}

export default PromoBanner;
